<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row class="justify-content-end">
        <!-- Search -->
        <!-- <b-col
          cols="6"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.findCode')"
            />
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click="SearchData(searchQuery)"
            > Cari
            </b-button>
          </div>
        </b-col> -->

        <!-- Entry -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <div class="col col-lg-auto">
              <b-button
                variant="success"
                @click="ExportExcel"
              > 
                <feather-icon icon="DownloadIcon" /> {{ $t('globalActions.excel') }}
              </b-button>
          </div>
          <div class="col col-lg-auto">
              <b-button
                variant="primary"
                :to="{ name: 'apps-master-data-product-sub-category-add'}"
              >
                <feather-icon icon="PlusIcon" /> {{ $t('globalActions.create') }}
              </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #head()="{ field }">
        <table-search-column
          :field="field"
          :fields="tableColumns"
          :focused-field="focusedSearchField"
          @set-focused-field="(value) => focusedSearchField = value"
          @search-data="(params) => {searchData(params); dataExcel.searchData(params)}"
        />
      </template>

      <!-- Column: Product Category -->
      <template #cell(code)="data">
        <b-link
          :to="{ name: 'apps-master-data-product-sub-category-detail', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          {{ data.item.code }} - {{ data.item.name }} 
        </b-link>
      </template>

      <!-- Column: Product Category Description -->
      <template #cell(accountTypes)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.description }}
        </span>
      </template>

        <!-- Column: Audit -->
      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
        {{ item.updated_process }}  By {{ item.getUser.user_name }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>
      
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('globalSingular.showing') }} {{ dataMeta.from }} {{ $t('globalSingular.to') }} {{ dataMeta.to }} {{ $t('globalSingular.of') }} {{ dataMeta.of }} {{ $t('globalSingular.entries') }}</span>
        </b-col>

        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  BSpinner
} from 'bootstrap-vue'
import TableSearchColumn from "@/components/TableSearchColumn.vue"

// import useList from './useList'
import useListTablePage from '@/comp-functions/useListTablePage'
import useLocalization from '@/comp-functions/utils/useLocalization'
import { ref } from '@vue/composition-api'
import { unformatNumber } from '@/utils/formatter'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    TableSearchColumn
  },


  setup () {
    const { $t } = useLocalization()

    const dataExcel = ref(null)

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage,
      searchData,
      customSearchQuery
    } = useListTablePage({ url: "master/product-sub-category?" })
    dataExcel.value = useListTablePage({ url: `master/product-sub-category?${customSearchQuery.value}&no_pagination=true` })

    // Table Handlers
    const tableColumns = ref([
      {
        key: 'code',
        label: 'Category',
        thClass: 'px-1',
        tdClass: 'px-1',
        sortable: true,
        search: { type: 'text', value: '' },
      },
      {
        key: 'description',
        label: 'Description',
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '' },
      },
      {
        key: 'headUser.user_name',
        label: $t('globalSingular.approvalUser'),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: {
          type: 'text',
          value: '',
          key: 'head_user_name'
        },
      },
      {
        key: 'audit',
        label: $t('globalSingular.audit'),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '' }
      },
    ])

    return {
      tableColumns,
      searchData,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage,
      dataExcel
    }
  },


  methods: {
    ExportExcel () {
      import('@/vendors/Export2Excel').then(async (excel) => {
        const list = this.dataExcel.itemLists
        for (let index = 0; index < list.length; index++) {
          list[index].code = list[index].code + '-' + list[index].name
          list[index].audit = list[index].updated_process + ' By ' + list[index].getUser.user_name + ' Date ' + list[index].updated_at
        }

        const date = new Date();
        
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
      
        let currentDate = `${day}-${month}-${year}`;

        const headerTitle = this.tableColumns.map(column => column.label)

        const headerVal = this.tableColumns.map(column => column.key)

        const data = this.formatJson(headerVal, list).map((row) => {
          return row.map((cell) => {
            return unformatNumber(cell);
          })
        });
        excel.export_json_to_excel({
          multiHeader: [
            ['Master Data', '', '', '', '', '', ''],
            ['Product Sub Category', '', '', '', '', '', ''],
            [currentDate, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Master data Product Sub Category`,
          autoWidth: true,
          bookType: 'xlsx'
        })

      })

    },
        formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>
